import React, { useLayoutEffect, useState } from 'react';
import { Box, Typography } from '@material-ui/core';
import PropTypes from 'prop-types';
import { DateTime } from 'luxon';
import { makeStyles } from '@material-ui/styles';
import clsx from 'clsx';
import { useTranslation } from 'react-i18next';
import { zIndices } from '../../theme';

export default function PromotionExpired(props) {
  const { isPromotionExtended, expirationDate } = props;
  const classes = useStyles();
  const { t } = useTranslation();
  const [top, setTop] = useState(0);

  useLayoutEffect(() => {
    function updateSize(entries) {
      if (!entries?.length > 0) return;
      const header = entries[0];
      setTop(header.contentRect.height - 4);
    }

    const resizeObserver = new ResizeObserver(updateSize);
    resizeObserver.observe(document.querySelector('header'));

    updateSize();

    return () => {
      resizeObserver.disconnect();
    };
  }, [setTop]);

  if (!expirationDate) {
    return null;
  }

  const isExpired = expirationDate && DateTime.fromISO(expirationDate) < DateTime.now();

  if (!isExpired && !isPromotionExtended) {
    return null;
  }

  return (
    <Box
      className={clsx(classes.ribbonBox, isExpired ? classes.isExpired : '')}
      style={{ top }}
    >
      <Typography
        className={clsx(classes.ribbonText, isExpired ? classes.isExpiredText : '')}
        variant="h6"
      >
        {!isExpired && isPromotionExtended ? t('components.molecules.PromotionExpired.promotionExtended') : ''}
        {isExpired ? t('components.molecules.PromotionExpired.actionExpired') : ''}
      </Typography>
    </Box>
  );
}

PromotionExpired.propTypes = {
  isPromotionExtended: PropTypes.bool,
  expirationDate: PropTypes.string,
};
PromotionExpired.defaultProps = {
  isPromotionExtended: null,
  expirationDate: null,
};

const useStyles = makeStyles((theme) => ({
  ribbonBox: {
    right: 0,
    width: 0,
    height: 0,
    position: 'absolute',
    borderTop: `180px solid ${theme.palette.primary.main}`,
    borderLeft: '180px solid transparent',
    pointerEvents: 'none',
    zIndex: zIndices.mobileMainMenu - 1,
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      width: '100%',
      height: 'unset',
      borderTop: 'unset',
      borderLeft: 'unset',
      backgroundColor: theme.palette.primary.main,
      borderBottom: '1px solid white',
    },
  },
  isExpired: {
    left: 0,
    right: 'unset',
    borderLeft: 'unset',
    borderRight: '180px solid transparent',
    [theme.breakpoints.down('sm')]: {
      borderRight: 'unset',
    },
  },
  ribbonText: {
    position: 'absolute',
    color: 'white',
    textAlign: 'center',
    fontSize: '1.8em',
    lineHeight: '1.1em',
    fontWeight: '900',
    transform: 'rotate(45deg) translateY(-20px) translateX(-190px)',
    [theme.breakpoints.down('sm')]: {
      position: 'relative',
      fontSize: '0.8em',
      fontWeight: '600',
      padding: '7px 0',
      transform: 'unset',
    },
  },
  isExpiredText: {
    transform: 'rotate(-45deg) translateY(-110px) translateX(95px)',
    [theme.breakpoints.down('sm')]: {
      transform: 'unset',
    },
  },
}));
