/* eslint-disable consistent-return */
import { bootstrap, ContentBlockRenderer } from '@plugins/next-cms-core';
import DefaultTemplate from '@components/templates/DefaultTemplate';
import Error from 'next/error';
import Head from 'next/head';
import PageInsetTop from '@components/atoms/PageInsetTop';
import PropTypes from 'prop-types';
import { gql } from '@apollo/client';
import { useHideConsentOnPrivacyPage } from '@lib/gdpr';
import Banner from '@components/molecules/Banner';
import { Box } from '@material-ui/core';
import PromotionExpired from '@components/molecules/PromotionExpired';
import { siteName } from '../../constants';
import { components } from '../../cmsconfig';

/**
 * This is the entry point for the cms application. It takes an id
 * and fecthes the data from the database for the target Page.
 *
 */
// eslint-disable-next-line no-unused-vars
export default function CmdCoreAppIndex({ path, page }) {
  useHideConsentOnPrivacyPage([
    '/datenschutz',
    '/impressum',
  ]);

  if (!page) {
    return (
      <Error statusCode={404} title="Inhalt nicht gefunden" />
    );
  }

  if (!page.publishedAt) {
    return (
      <Error statusCode={410} title="Inhalt ist nicht mehr vorhanden" />
    );
  }

  const {
    robots,
    metaPageTitle,
    metaPageDescription,
    isTopContentOffset,
    expirationDate,
    isPromotionExtended,
  } = page;
  const componentData = {
    id: page.id,
    blocks: page.content ?? [],
  };

  let isBannerRendered = false;

  return (
    <>
      <Head>
        <title>
          {`${metaPageTitle ?? ''} | ${siteName}`}
        </title>
        <meta content={metaPageDescription} name="description" />
        {robots && robots !== 'index' && (
          <meta content={robots} name="robots" />
        )}
      </Head>
      <DefaultTemplate>
        {isTopContentOffset && <PageInsetTop />}
        <PromotionExpired
          expirationDate={expirationDate}
          isPromotionExtended={isPromotionExtended}
        />
        <ContentBlockRenderer
          data={componentData}
          context={{ page }}
          // eslint-disable-next-line react/no-unstable-nested-components
          preComponentRender={(component) => {
            // eslint-disable-next-line no-underscore-dangle
            if (!isBannerRendered && !PRE_RENDER_SKIP_COMPONENTS.includes(component.__typename)) {
              isBannerRendered = true;

              return (
                <Box my={3}>
                  <Banner />
                </Box>
              );
            }
          }}
        />
      </DefaultTemplate>
    </>
  );
}

CmdCoreAppIndex.propTypes = {
  path: PropTypes.string.isRequired,
  page: PropTypes.object,
};

CmdCoreAppIndex.defaultProps = {
  page: null,
};

CmdCoreAppIndex.getInitialProps = async ({
  query, res, graphQlClient, locale,
}) => {
  const path = getPathFromQuery(query);

  try {
    const { data, error } = await graphQlClient.query({
      query: FETACH_PAGE,
      variables: {
        path,
        locale,
        localeStr: locale,
      },
    });

    if (error) {
      throw error;
    }

    if (!data.resolvePageByPath && res) {
      res.statusCode = 404;
    } else if (!data.resolvePageByPath?.publishedAt && res) {
      res.statusCode = 410;
    }

    if (res && data.cacheState.isEnabled) {
      res.setHeader('Cache-Control', 'public, max-age=3600');
    }

    return {
      path,
      page: data.resolvePageByPath,
      pageBottomConfig: {
        bottomInfoText: data.resolvePageByPath?.bottomInfoText,
      },
      isGlobalNoticeHidden: Boolean(data.resolvePageByPath?.isGlobalNoticeHidden),
      canonicalUrl: data.resolvePageByPath?.metaCanonicalUrl,
    };
  } catch (error) {
    if (error.networkError?.result?.errors) {
      console.warn(JSON.stringify(error.networkError?.result?.errors, null, 2));
    }

    throw error;
  }
};

function getPathFromQuery(query) {
  if (!Array.isArray(query.path)) {
    return query.path;
  }

  return query.path.join('/');
}

const { graphQlFragments } = bootstrap({
  components,
});

const PRE_RENDER_SKIP_COMPONENTS = [
  'ComponentContentPageHeader',
  'ComponentContentCarSearchFilter',
  'ComponentContentModelRequestForm',
];

const FETACH_PAGE = gql`
query ResolvePageByPath($path: String!, $locale: I18NLocaleCode!, $localeStr: String!) {
  resolvePageByPath(path: $path, locale: $locale) {
    name
    publishedAt
    publishDate
    metaPageTitle
    expirationDate
    bottomInfoText
    isTopContentOffset
    isPromotionExtended
    isGlobalNoticeHidden
    metaPageDescription
    metaCanonicalUrl
    robots
    content {
      ${graphQlFragments}
    }
  }
  cacheState {
    isEnabled
  }
}
`;
